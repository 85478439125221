<template>
  
  <global-header :user="currentUser"></global-header> 
  <!-- <message v-if="error.status" :message="error.message" type="error"></message>  -->
  <Loader v-if="isLoading" :text="'努力加载中...'"></Loader>
  <div class="container">
    <router-view></router-view>
  </div>
  <footer class="text-center py-4 rext-secondary bg-light mt-6">
    <small>
      <ul class="list-inline mb-0">
        <li class="list-inline-item">© 2020 知乎专栏</li>
        <li class="list-inline-item">课程</li>
        <li class="list-inline-item">文档</li>
        <li class="list-inline-item">联系</li>
        <li class="list-inline-item">更多</li>
      </ul>
    </small>
  </footer>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import Loader from './components/Loader.vue'
// import Message from './components/Message.vue'
import createMessage from './components/createMessage'
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalHeader from './components/GlobalHeader.vue'
import {GlobalDataProps} from './store'

export default defineComponent({
  name: 'App',
  components:{
    GlobalHeader,
    Loader,
    // Message
  },
  setup(){

    const store = useStore<GlobalDataProps>()
    const currentUser = computed(() => store.state.user)
    const isLoading = computed(()=>store.state.loading)
    const error = computed(()=>store.state.error)
    const token = computed(()=>store.state.token)

    //防止登录后刷新页面 重新获取用户信息 做到持久化登录状态
    onMounted(() => {
      // 此处 XXX （为获取的用户名，为XXX表示没有获取到用户名）
      // isLogin 为 false 头部显示的为登录和注册就算是 token 值保存到本地也没有意义
      // islogin 为 true 头部显示的是 你好 XXX 此时 XXX并没有获取到用户信息，前提是已经保存了token
      // 为什么islogin要取反，她的初始值为false在第一次登录之后值为true但是在刷新后就会变回初始值false
      // 辅助判断isLogin true或者false并且判断token存在
      // if (!currentUser.value.isLogin && token.value) {
      //   // 添加一个请求头的携带token
      //   axios.defaults.headers.common.Authorization = ` Bearer ${token.value}`
      //   // 为什么如下方式不可行
      //   // 因为此时去执行login登录的请求没有获取到登录所需要的值
      //   // store.dispatch('loginAndFecth')
      //   // 解决方案如下：
      //   // const payload ={
      //   //   email:'111@test.com',
      //   //   password:'111111'
      //   // }
      //   // store.dispatch('loginAndFecth')
      //   // 再次获取用户信息，使 XXX 变成我们登录的用户名
      //   // 提交actions方法
      //   store.dispatch('fetchCurrentUser')
      // }
      watch(()=>error.value.status,()=>{
        const {status,message}=error.value
        if(status && message){
          createMessage(message,'error')
        }
      })
    });
    return{
      currentUser,
      isLoading,
      error,
    }
  }
})
</script>

<style lang="less">


</style>
