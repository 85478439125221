import { createApp } from 'vue'
import { router } from './router'
import  store from "./store"
import App from './App.vue'
import axios from 'axios'

axios.defaults.baseURL = 'http://api.vikingship.xyz/api'
axios.interceptors.request.use(config => {
  store.commit('setLoading', true)
  // 周期开始时重置消息提醒
  store.commit('setError', {status:false, message: ''})
  return config
})
axios.interceptors.response.use(config => {
  store.commit('setLoading', false)
  return config
}, e => {
  const{error} = e.response.data
  store.commit('setError', {status:true, message: error})
  store.commit('setLoading', false)
   
})
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')
