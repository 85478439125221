import { createStore,Commit } from 'vuex'
import axios, { AxiosRequestConfig } from 'axios'
import { objToArr, arrToObj } from './helper';
// import { testProps } from './testData'
// 上传文件返回的数据类型
export interface ResponseType<p = {}>{
  code:number;
  msg: string;
  data: p;
}
export interface ImageProps{
  _id?: string;
  url?: string;
  createdAt?: string;
  fitUrl?: string;
}
export interface ColumnProps {
  _id: string;
  title: string;
  avatar?: ImageProps;
  description: string;
}
export interface postProps{
  _id?: string;
  title: string;
  excerpt?: string;
  content?: string;
  image?: ImageProps | string;
  createdAt?: string;
  column: string;
  author?: string | UserProps; 
}
export interface UserProps {
  isLogin: boolean;
  nickName?: string;
  _id?: string;
  column?: string;
  email?: string;
  avatar?: ImageProps;

  // token?: string;
}
interface ListProps<p> {
  [id: string]: p
}
export interface GlobalErrorProps{
  status: boolean;
  message?: string;
}
export interface GlobalColumnsProps{
  data:ListProps<ColumnProps>,
  currentPage: number, total: number
}
export interface GlobalPostProps{
  data: ListProps<postProps>;
  loadedColumns: ListProps<{total?: number; currentPage?:number}>
}
// 全局数据支撑
export interface GlobalDataProps {
  error: GlobalErrorProps;
  token: string;
  loading: boolean;
  // columns: ListProps<ColumnProps>;
  // posts: ListProps<postProps>;
  columns: GlobalColumnsProps;
  posts: GlobalPostProps;
  user: UserProps;
}
const getAndCommit = async(url:string, mutationName:string, commit:Commit) => {
  // const token = localStorage.getItem('token');
  // const {data} = await axios.get(url, {headers: {Authorization: `Bearer ${token}`}})
  const {data} = await axios.get(url)
  commit(mutationName, data)
  return data
}
const postAndCommit = async(url:string, mutationName:string, commit:Commit, payload:any) => {
  const {data} = await axios.post(url, payload)
  commit(mutationName, data)
  return data
}
const asyncAndCommit = async(url:string, mutationName:string, commit:Commit,
  config: AxiosRequestConfig = { method: 'get'}, extraData?: any) => {
  const { data } = await axios(url, config)
  if(extraData){
    commit( mutationName, {data, extraData})
  }else{
    commit( mutationName, data)
  }
  return data
}

const store = createStore<GlobalDataProps>({
  state: {
    error: {status: false},
    token: localStorage.getItem('token') || '',
    loading: false,
    columns: { data:{}, currentPage: 0, total: 0},
    posts: {data:{}, loadedColumns:{}},
    user: { isLogin: false },
  },
  mutations: {
    // 创建文章
    createPost(state, newpost){
      state.posts.data[newpost._id] 
    },
    // 删除文章
    deletePost(state, {data}){
      delete state.posts.data[data._id]
    },
    // 专栏列表提交处理函数
    fethColumns(state, rawData) {
      const{data} = state.columns
      const{list, count, currentPage} = rawData.data
      state.columns = {
        data : {...data, ...arrToObj(list)},
        total : count,
        currentPage: currentPage*1
      }
      // state.columns.data = arrToObj(rawData.data.list)
      // state.columns.isloaded = true
    },
    // 单个专栏详情提交处理函数
    fethColumn(state, rawData) {
      state.columns.data[rawData.data._id] = rawData.data
    },
    // 获取专栏下的文章信息保存到state中的posts中
    fethPosts(state, {data: rawData, extraData: columnId, }) {
      const{list, count, currentPage} = rawData.data
      const{data, loadedColumns} = state.posts
      const listData = list as postProps[]
      state.posts.data = {...data, ...arrToObj(listData)}
      loadedColumns[columnId] ={
        total: count, 
        currentPage: currentPage
      }
    },
    // fethPosts(state, {data: rawData, extraData: columnId}) {
    //   state.posts.data = {...state.posts.data, ...arrToObj(rawData.data.list)}
    //   state.posts.loadedColumns.push(columnId)
    // },
    fethPost(state, rawData) {
      state.posts.data[rawData.data._id] = rawData.data
    },
    // 更新文章
    updatePost(state, {data}) {
      state.posts.data[data._id] = data
    },
    setLoading(state, status) {
      state.loading = status
    },
    setError(state, e:GlobalErrorProps) {
      state.error = e
    },
    // 获取用户登录信息
    fetchCurrentUser(state,rawData) {
      state.user = { isLogin: true, ...rawData.data}
    },
    login(state, rawData){
      const{token} = rawData.data
      // state.user = { isLogin: true, token }; 
      state.token = token
      localStorage.setItem('token', token)

      // axios.defaults.headers.common.Authorization = `Bearer ${token}`
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },
    logOut(state){
      localStorage.removeItem('token')
      state.token = ''
      delete axios.defaults.headers.common.Authorization
    }
  },
  actions: {
    // 获取专栏列表
    // fethColumns(content) {
    //   axios.get('/columns').then(resp => {
    //     content.commit('fethColumns', resp.data)
    //   })
    // },
    // 用async,await修改异步代码
    // async fethColumns({commit}) {
    //   const {data} = await axios.get('/columns')
    //   commit('fethColumns', data)
    // },
    // 抽离getAndCommit函数
    fethColumns({state, commit},params = {}) {
      const { currentPage = 1, pageSize = 6} = params
      if(state.columns.currentPage < currentPage){
        return asyncAndCommit(`/columns?currentPage=${currentPage}&pageSize=${pageSize}`, 'fethColumns', commit)
      }
      // if(!state.columns.isloaded){
      //   getAndCommit('/columns', 'fethColumns', commit)
      // }
    },

    // 获取单个专栏的详情
    fethColumn({state, commit}, cid) {
      if(!state.columns.data[cid]){
        getAndCommit(`/columns/${cid}`, 'fethColumn', commit)
      }
    },
    //  获取专栏下的文章信息
    fethPosts({state, commit},{cid, params = {}}) {
      const {currentPage = 1, pageSize = 3 } = params
      const {loadedColumns} = state.posts
      const loadedCurrentPage = (loadedColumns[cid] && loadedColumns[cid].currentPage)||0
      // Object.keys： 获取map的键
      // 存储的数据不包含当前专栏下的文章信息或者
      if(!Object.keys(loadedColumns).includes(cid) || loadedCurrentPage < currentPage){
        return asyncAndCommit(`/columns/${cid}/posts?currentPage=${currentPage}&pageSize=${pageSize}`, 'fethPosts', commit, { method: 'get' }, cid)
      }

      // if(!state.posts.loadedColumns.includes(cid)){
      //   return asyncAndCommit(`/columns/${cid}/posts`, 'fethPosts', commit, {method: 'get'}, cid)
      // }
    }, 
    // 获取文章详情
    fethPost({state, commit},id) {
      const currentPost = state.posts.data[id]
      // 如果currentPost不存在或者（content）currentPost.content不存在说明是从专栏列表获取的，缺内容所以需要重新获取
      if(!currentPost || !currentPost.content){
        return getAndCommit(`/posts/${id}`, "fethPost", commit)
      }else{
      // 修改文章也页用到，直接在createPost.vue无法直接获取promise数据所以需要手动返回promise对象
        return Promise.resolve({data: currentPost})
      }
    },
    // 更新文章/
    updatePost({commit}, {id, payload}) {
      return asyncAndCommit(`/posts/${id}`, 'updatePost', commit, {
        method: 'patch',
        data: payload
      })
    },
    //通过token获取用户信息
    fetchCurrentUser({commit}){ 
      getAndCommit('/user/current', 'fetchCurrentUser', commit)
    },
    login({commit}, payload) {
      return postAndCommit('/user/login','login',commit, payload)
    },
    createPost({commit}, payload) {
      return postAndCommit('/posts','createPost',commit, payload)
    },
    // 删除文章
    deletePost({commit}, id) {
      return asyncAndCommit(`/posts/${id}`,'deletePost',commit, {method: 'delete'})
    },

    //组合actions
    loginAndFetch({dispatch}, loginData){
      return dispatch('login', loginData).then(()=> {
        return dispatch('fetchCurrentUser')
      })
    }
  },
  getters: {
    getColumns: (state)=> {
      return objToArr(state.columns.data)
    },
    getColumnById: (state) => (id: string) => {
      return state.columns.data[id]
    },
    getPostsByCid: (state) => (cid: string) => {
      return objToArr(state.posts.data).filter(post => post.column === cid)
    },
    getPost: (state) =>(id: number) => {
      return state.posts.data[id]
    },
    // 获取专栏文章的当前页
    getPostsCurrentPageByCid: (state) => (cid: string) => {
      if (state.posts.loadedColumns[cid]) {
        return state.posts.loadedColumns[cid].currentPage
      } else {
        return 0
      }
    },
    // 获取专栏文章的总数量
    getPostsCountByCid: (state) => (cid: string) => {
      if (state.posts.loadedColumns[cid]) {
        return state.posts.loadedColumns[cid].total
      } else {
        return 0
      }
    }
  },
})
export default store;
