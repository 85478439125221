  <template>
    <nav class="navbar bg-dark px-4" >
      <div class="nav">
        <router-link class="navbar-brand"  to="/">DB知乎专栏</router-link>
        <ul v-if="!user.isLogin" class="list-inLine ">
          <li class="list-inline-item"><router-link to="/Login" class=" btn btn-outline-secondary">登录</router-link></li>
          <li class="list-inline-item"><router-link to="/Signup" class="btn btn-outline-secondary">注册</router-link></li>
        </ul>
        <ul v-else class="list-inline mb-0">
          <Dropdown :title="`你好 ${user.nickName}`">
            <DropdownItem><router-link to="/create" class="dropdown-item">新建文章</router-link></DropdownItem>
            <DropdownItem><router-link v-if="user && user._id" :to="`/columnDetail/${user.column}`" class="dropdown-item" >个人专栏</router-link></DropdownItem>
            <dropdown-item disabled><a href="#" class="dropdown-item">编辑资料</a></dropdown-item>
            <dropdown-item><router-link to="/" class="dropdown-item" @click="exit()">退出登录</router-link></dropdown-item>
          </Dropdown>
        </ul>
      </div>
    </nav>

  </template>


  <script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import Dropdown from './Dropdown.vue'
    import DropdownItem from './DropdownItem.vue'
    import { UserProps, GlobalDataProps } from '../store'
    import {useStore} from 'vuex'
    export default defineComponent({
      name: 'GlobalHeader',
      props:{
        user:{
          type: Object as PropType<UserProps>,
          required: true
        }
      },
      components:{
        Dropdown,
        DropdownItem
      },
      setup(props){
        const store = useStore<GlobalDataProps>()
        // 退出登录：页面跳转首页，清空state里的token(清除本地缓存))，state里的isLogin=false
        const exit = () => {
          store.commit('logOut')
          store.state.user.isLogin = false
        }
        // const PersonalColumn = () => {
        //   console.log(props.user)
        //   store.dispatch('fethPosts', props.user.column)
        //   store.dispatch('fethColumn', props.user.column)

        // }
        
        return{
          exit,
          // PersonalColumn
        }
      }
    })
  </script>
<style scoped lang="less">

  .nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin:auto;
    height: 40px;

    .list-inline-item{
      line-height: 40px;
      
    } 
  }
  .navbar-brand{
    color: #fff;
    display: block;
    height: 40px;
    padding: 0;
  }
  .dropdown-item{
    color: black
  }
  
</style>