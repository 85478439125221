import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab4ecab4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "PersonalColumn"
}
const _hoisted_2 = { class: "information" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "copywriting" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "introduce" }
const _hoisted_7 = { class: "d-grid gap-2 col-4 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_post_list = _resolveComponent("post-list")!

  return (_ctx.column)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.column && _ctx.column.avatar)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.column.avatar && _ctx.column.avatar.fitUrl,
                alt: _ctx.column.title
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.column.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.column.description), 1)
          ])
        ]),
        _createVNode(_component_post_list, { list: _ctx.posts }, null, 8, ["list"]),
        _createElementVNode("div", _hoisted_7, [
          (!_ctx.isLastPage)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-outline-primary mt-2 mb-5",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMorePage && _ctx.loadMorePage(...args)))
              }, " 加载更多 "))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}