<template v-if="list">
  <div class="columnList">
    <div class="article" v-for="post in list" :key="post._id">
      <router-link :to="`/posts/${post._id}`" class="columnName">{{post.title}}</router-link>
      <div class="content">
        <div class="img" v-if="post.image"><img v-if="(typeof post.image != 'string')" :src="(post.image.url)" alt=""></div>
        <p>{{post.excerpt}}</p>
      </div>
      <p>{{post.createdAt}}</p>
    </div>
  </div>
</template> 

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { generateFitUrl } from '../helper'
import { ImageProps, postProps } from '../store'

export default defineComponent({
  name: 'PostList',
  props: {
    list: {
      required: true,
      type: Array as PropType<postProps[]>
    }
  },
  setup (props) {
    const posts = computed(() => {
      return props.list.map(post => {
        generateFitUrl(post.image as ImageProps, 200, 110)
        return post
      })
    })
    return {
      posts
    }
  }
})

</script>

<style scoped lang="less">
 .columnList{
    margin-top: 20px;
    .article{
      border: 1px solid #e1e1e1;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 20px;
      a{
        color: #000;  
        text-decoration: none; 
        font-size: 24px;
        font-weight: 500;
        display: block;
        margin-bottom: 20px;
      }
      .content{
        display: flex;
        justify-content: flex-start;
        .img{
          width:220px;
          height: 120px;
          margin-right: 20px;
        }
        img{
          width:220px;
          height: 120px;

        }
        p{
          font-size: 16px;
          overflow: hidden; /* 隐藏溢出部分 */  
          display: -webkit-box; /* 使用WebKit特定的属性 */  
          -webkit-line-clamp: 5; /* 设置显示的行数 */  
          -webkit-box-orient: vertical; /* 容器方向设置为垂直 */  
          text-overflow: ellipsis; /* 显示省略号 */  
          color: #888;
          margin: 0
        }
      }
      p{
        color: #6c757d;
        margin: 20px 0 0 0;
      }
    }
  }
</style>