import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "dropdown",
  ref: "dropdownRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "btn btn-secondary dropdown-toggle",
      type: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleOpen && _ctx.toggleOpen(...args)))
    }, _toDisplayString(_ctx.title), 1),
    _createTextVNode(" " + _toDisplayString(_ctx.isOpen) + " ", 1),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("ul", {
          key: 0,
          class: "dropdown-menu mt-2",
          style: _normalizeStyle({display:'block', background:'white', boxShadow:'0px 1px 4px 1px rgba(61,137,240,0.16)'})
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 4))
      : _createCommentVNode("", true)
  ], 512))
}