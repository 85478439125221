// 新建文章
<template>
  <div class="create-post-page">
    <h4>{{isEditMode ? '编辑文章' : '新建文章'}}</h4>
    <Uploader
      class="d-flex align-items-center justify-content-center bg-light text-secondary w-100 my-4"
      :beforeUpload="uploadCheck"
      @file-uploaded="handleFileUploaded"
      action="/upload"
      :UpLOadedData="UpLOadedData"
    >
      <h2>点击上传头像</h2>
      <template #loading>
        <div class="d-flex">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading ...</span>
          </div>
          <h2>正在上传</h2>
        </div>
      </template>
      <template #uploaded = "dataProps">
        <img :src="dataProps.uploadedData.data?.url" >
      </template>
    </Uploader>
    <Validate-form @form-submit="onFormSubmit" class="from">
      <!-- <div class="picture"><p>点击上传头像</p></div> -->
      <div class="mb-3">
        <label class="form-label">文章标题：</label>
        <validate-input
          type="email"
          placeholder="请输入文章标题"
          :rules="titleRules"
          v-model="titleVal"
          ref="inputRef"
        ></validate-input>
      </div>
      <div class="mb-3">
        <label class="form-label">文章详情：</label>
        <validate-input
          rows="10"
          type="text"
          tag="textarea"
          placeholder="请输入文章详情"
          :rules="contentRules"
          v-model="contentVal"
        ></validate-input>
      </div>
      
      <template v-slot:submit>
        <span class="btn btn-primary" >{{isEditMode ? '更新文章' : '发布文章'}}</span>
      </template>
    </Validate-form>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, onMounted} from 'vue'
  import { useStore } from 'vuex'
  import {useRouter, useRoute} from 'vue-router'
  import { GlobalDataProps, postProps, ResponseType, ImageProps } from '@/store'
  import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
  import ValidateForm from '../components/ValidateForm.vue'
  import Uploader from '../components/Uploader.vue'
  import {beforeUploadCheck} from '../helper'
  import createMessage from '@/components/createMessage'
  
  export default defineComponent({
    name: 'CreatePost',
    components:{
      ValidateInput,
      ValidateForm,
      Uploader,
    },
    setup(){
      const UpLOadedData = ref()
      const store = useStore<GlobalDataProps>()
      const route = useRoute()  
      const router = useRouter()
      const inputRef = ref<any>()
      const titleVal = ref('')
      const contentVal = ref('')
      const isEditMode = !! route.query.id

      let imageId = ''
      const titleRules: RulesProp = [
        {type: 'required', message: '文章标题不能为空'},
        // {type: 'email', message: '请输入正确的电子邮箱格式'}
      ]
      const contentRules: RulesProp = [
        {type: 'required', message: '文章内容不能为空'},
        // {type: 'password', message: '请输入正确的密码格式'}
      ]
      onMounted(() => {
        if (isEditMode){
          store.dispatch('fethPost', route.query.id).then((rawData: ResponseType<postProps>) => {
            const currentPost = rawData.data
            if (currentPost.image){
              UpLOadedData.value = {data:currentPost.image}
              // console.log(UpLOadedData)
            }
            titleVal.value = currentPost.title
            console.log(titleVal.value)
            contentVal.value = currentPost.content || ''
          })
        }
      })
      const handleFileUploaded = (rawData: ResponseType<ImageProps>) => {
        if(rawData && rawData.data && rawData.data._id){
          imageId = rawData.data._id
        }
      }
      const onFormSubmit = (result: boolean) => {
        if(result){
         const {column, _id} = store.state.user
         if(column){
          const newPost : postProps = {
            title: titleVal.value,
            content: contentVal.value,
            column: column,
            author: _id
            // createdAt: new Date().toLocaleString()
          }
          if(imageId){
            newPost.image = imageId
          }
          const actionName = isEditMode ? 'updatePost' : 'createPost'
          const sendData = isEditMode ? {
            id: route.query.id,
            payload: newPost
          } : newPost
          store.dispatch(actionName, sendData).then(()=>{
             createMessage('发表成功，3秒后跳转到文章', 'success')
             setTimeout(() => {
              router.push({ name:'columnDetail', params:{id:column}})
             }, 2000);
          })
         }
         
        }
      }
      const uploadCheck = (file: File) => {
        const result = beforeUploadCheck (file, {format: ['image/jpeg','image/png'], size: 1})
        const {passed, error} = result
        if (error == 'format'){
          createMessage('上传图片只能是JPG/PNG格式！', 'error')
        }
        if (error == 'size') {
          createMessage('上传图片大小不能超过1MB', 'error')
        }
        return passed
      }
      return{
        uploadCheck,
        titleRules,
        contentRules,
        titleVal,
        contentVal,
        onFormSubmit,
        inputRef,
        handleFileUploaded,
        UpLOadedData,
        isEditMode
      }
    }
  })
</script>
<style lang="less">
  .create-post-page .file-upload-container{
    height: 200px;
    cursor: pointer;
  }
  .create-post-page .file-upload-container img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .create-post-page{
    h4{
      font-size: 24px; 
      margin: 20px 0;
    }
    .from{ 
      margin-top: 20px;
      // .picture{
      //   background-color: #f8f9fa;
      //   height: 200px;
      //   text-align: center;
      //   line-height: 200px;
      //   margin-bottom: 20px;
      //   p{
      //     color: rgba(0,0,0,.6);
      //     font-size: 28px;
      //     width: 100%;
      //   }
      // }
      .btn{
        margin-bottom: 20px;
      }
    }
  }
</style>