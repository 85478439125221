import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c93fb3ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loading-content" }
const _hoisted_2 = {
  class: "spinner-border text-primary",
  role: "status"
}
const _hoisted_3 = { class: "visually-hidden" }
const _hoisted_4 = {
  key: 0,
  class: "text-primary small"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({backgroundColor: _ctx.background || ''}),
    class: "d-flexjustify-content-center align-items-center h-100 loading-container"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text || 'loading'), 1)
      ]),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ])
  ], 4))
}