<template>
  <div :style="{backgroundColor: background || ''}" class="d-flexjustify-content-center align-items-center h-100 loading-container">
    <div class="loading-content">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{text || 'loading'}}</span>
      </div>
      <p v-if="text" class="text-primary small">{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import usrDOMCreate from '../hooks/useDOMCreate'
export default defineComponent({
  props: {
    text:{
      type: String
    },
    background:{
      type: String
    }
  },
  setup(){
    usrDOMCreate
  }
})
</script>

<style scoped lang="less">
.loading-container{
  background: rgba(255, 255, 255, .5);
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .loading-content{
    position: relative;
    top: 50%;
    text-align: center;
  }
}
</style>