<template>
  <teleport to="#message">
    <div
    :class="classObject"
    v-if="isVisible"
    class="alert message-info fixed-top w-50 mx-auto d-flex justify-content-between mp-2"
    role="alert"
    >
      <h5 class="message">{{ message }}</h5>
      <button type="button" class="btn-close" aria-label="Close" @click.prevent="hide"></button>
    </div>
  </teleport>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from 'vue'
import usrDOMCreate from '../hooks/useDOMCreate'
export type MessageType = 'success' | 'error' | 'default'
export default defineComponent({
  props:{
    message: String,
    type:{
      type: String as PropType<MessageType>,
      default: 'default'
    }
  },
  emits:['close-message'],
  setup(props,context){
    usrDOMCreate('message')
    const isVisible = ref(true)
    const classObject = {
      'alert-success' : props.type === 'success',
      'alert-danger' : props.type === 'error',
      'alert-primary' : props.type === 'default',
    }
    const hide = () => {
      isVisible.value = false
      context.emit('close-message', true)
    }
    return{classObject, hide, isVisible}
  }
})
</script>

<style scoped lang="less">

.alert{
  margin: 0;
  padding: 0 20px;
  height: 40px;
  .message{
    height: 32px;
    line-height: 40px;
  } ;
  button{
    margin-top:6px;
  }
}
</style>