<template>
  <div class="dropdown" ref="dropdownRef">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" @click="toggleOpen">
      {{title}}
    </button>
    {{ isOpen }}
    <ul v-if="isOpen" class="dropdown-menu mt-2" :style="{display:'block', background:'white', boxShadow:'0px 1px 4px 1px rgba(61,137,240,0.16)'}" >
     <slot></slot>
    </ul>
  </div>
</template> 

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import useClickOutside from '../hooks/useClickOutside'
export default defineComponent({
  name: 'Dropdown',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(){
    const isOpen = ref(false)
    const dropdownRef = ref<null | HTMLElement>(null)
    const toggleOpen = () => {
      isOpen.value = !isOpen.value
    }
    const isClickOutside = useClickOutside(dropdownRef)
    
    watch(isClickOutside, ()=>{
      if(isOpen.value && isClickOutside.value){
      isOpen.value = false
    }
    })
    return{
      isOpen,
      toggleOpen,
      dropdownRef
    }
  }
})


</script>

<style>

</style>