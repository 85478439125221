import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal d-block",
  tabindex: "-1"
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = {
  type: "button",
  class: "btn-close",
  "data-bs-dismiss": "modal",
  "aria-label": "Close"
}
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#modle" }, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.title), 1),
                _createElementVNode("button", _hoisted_6, [
                  _createElementVNode("span", {
                    "aria-hidden": "true",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
                  }, "×")
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-secondary",
                  "data-bs-dismiss": "modal",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
                }, "取消"),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCofirm && _ctx.onCofirm(...args)))
                }, "确定")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}