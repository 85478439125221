<template>
  <!-- <div class="mb-3 form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div> -->
  <div class="editEntry">
    <img src="../assets/images/callout.10de9efe.svg" style="width:267px; height:220px" alt="">
    <p>随心写作，自由表达</p>
    <div><router-link to="/create" type="button" class="btn btn-primary">开始写文章</router-link></div>
  </div>
  <!-- <Uploader action="/upload" :beforeUpload="beforeUpload" @file-uploaded="onFileUploaded" @file-uploaded-error="onFileUploadedError">
    <template #uploaded = "dataProps">
      <img :src="dataProps.uploadedData.data.url" alt="">
    </template>
  </Uploader> -->
  <div class="columnList">
    <h1>发现精彩</h1>
    <ColumnList :list="list"></ColumnList>
    <button class="addMore btn btn-outline-primary mt-2 mb-5 btn-block w-25"
    @click="loadMorePage" v-if="!isLastPage">
      加载更多
    </button>
  </div>

</template>


<script lang="ts">
   import { defineComponent, computed, onMounted } from 'vue'
   import { useStore } from 'vuex'
   import { GlobalDataProps, ResponseType, ImageProps } from '../store'
   import ColumnList from '../components/ColumnList.vue'
   import Uploader from '../components/Uploader.vue'
   import useLoadMore from '@/hooks/useLoadMore'
  //  import createMessage from '@/components/createMessage'
 
   export default defineComponent({
    name: 'Home',
    components:{
      ColumnList,
      Uploader
    },
    setup(){
      const total = computed(()=>store.state.columns.total)
      onMounted(()=>{
        store.dispatch('fethColumns', {pageSize : 6})
      })
      const store = useStore<GlobalDataProps>()
      // const list = computed(() => store.state.columns) 
      const list = computed(() => store.getters.getColumns) 
      const currentPage = computed(()=>store.state.columns.currentPage)
      const { loadMorePage, isLastPage} = useLoadMore('fethColumns', total, {pageSize:6, currentPage:(currentPage.value ? currentPage.value+1 : 2)})
      // const beforeUpload = (file: File) => {
      //   const isJPG = file.type === 'image/jpeg/png'
      //   if(!isJPG){
      //     createMessage('上传图片只能是JPG格式！', 'error')
      //   }
      //   return isJPG
      // }
      // // 上传成功触发的事件函数
      // const onFileUploaded = (rawData: ResponseType<ImageProps>) => {
      //   createMessage(`上传图片ID ${rawData.data._id}`, 'success')
      // }
      // // 上传失败触发的事件函数
      // const onFileUploadedError = (error: string) => {
      //   createMessage(`${error}`, 'error')
      // }
      return {
        list,
        loadMorePage,
        isLastPage
        // beforeUpload,
        // onFileUploaded,
        // onFileUploadedError,
      }
    }
   })
</script>
<style scoped lang="less">
  .editEntry{
    margin: 80px 0;
    display: flex;
    flex-direction:column; 
    align-items: center;
    p{
      font-size: 30px;
    }
  }
  .columnList{
    width: 100%;
    h1{
      text-align:center;
      font-weight: 500;
      font-size: 20px;
    }

    .addMore{
      margin: 0 auto;
      text-align: center;
      display:block;
    }
  }
</style>