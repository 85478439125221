<template>
  <div class="post-detail-page">
    <modal title="删除文章" :visible = "modalIsVisible"
      @modal-on-close = "modalIsVisible = false"
      @modal-on-confirm = "hideAndDelete"
    >
      <p>确定要删除这篇文章吗？</p>
    </modal>
    <div><span>首页</span>/<span>专栏首页</span>/<span>学英语是靠刷一百道题还是看一百部电影?</span></div>
    <article class="w-75 mx-auto mb-5 pb-3" v-if="currentPost">
      <img :src="currentImageUrl" alt="currentPost.title" class="rounded-lg img-fluid my-4" v-if="currentImageUrl">
      <h2 class="mb-4">{{currentPost.title}}</h2>
      <div class="user-profile-component border-top border-bottom py-3 mb-5 align-items-center row g-0">
        <div class="col">
          <user-profile :user="currentPost.author" v-if="typeof currentPost.author === 'object'"></user-profile>
        </div>
        <span class="text-muted col text-right font-italic">发表于：{{ currentPost.createdAt }}</span>
      </div>
      <div v-html="currentHtml"></div>
      <div v-if="showEditArea" class="btn-group mt-5">
        <router-link
          :to="{name:'createPost', query: { id: currentPost._id}}" 
          type="button" class="btn btn-success"
        >
          编辑
        </router-link>
        <button type="button" class="btn btn-danger" @click.prevent="modalIsVisible = true">删除</button>
      </div>
    </article>

  </div>
</template>

<script lang="ts">
// 1、编写html代码
// 2、编写样式
// 3、路由设计:/posts/:id
// 4、获取数据:面包屑首页/专栏首页/文章标题
              // 文章图片
              // 文章标题
              // 用户信息（头像、名称、说明），发表于
              // 文章内容
              // 编辑、删除

import { GlobalDataProps, ImageProps, postProps, UserProps, ResponseType } from '@/store'
import { defineComponent, onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute} from 'vue-router'
import UserProfile from '../components/UserProfile.vue'
import markdownit from 'markdown-it'
import Modal from '@/components/Modal.vue'
import createMessage from '@/components/createMessage'
import { router } from '@/router'


export default defineComponent ({ 
  name:'post-detail',
  components:{
    UserProfile,
    Modal
  },
  setup(){
    const store = useStore<GlobalDataProps>()
    const route = useRoute()  
    const currentId = route.params.id
    const modalIsVisible = ref(false)
    const md = markdownit()
    onMounted(() => {
      store.dispatch('fethPost', currentId)
    })
    const currentPost = computed<postProps>(()=> store.getters.getPost(currentId))

    // markdown格式转换成html
    const currentHtml = computed(()=>{
      if(currentPost.value && currentPost.value.content){
        return md.render(currentPost.value.content);
        // const mb = currentPost.value.content
        // return mb
      }
    })
    // 判断用户（编辑删除文章的）权限
    const showEditArea = computed(() => {
      const { isLogin, _id } = store.state.user
      if (currentPost.value && currentPost.value.author && isLogin){
        const postAuthor = currentPost.value.author as UserProps
        return postAuthor._id === _id
      }else{
        return false
      }
    })
    // 获取图片地址
    const currentImageUrl = computed(()=>{
      if(currentPost.value && currentPost.value.image){
        const { image } = currentPost.value
        return ( image as ImageProps).url + ''
      }
    })
    // 删除文章
    const hideAndDelete = () => {
      modalIsVisible.value = false
      store.dispatch('deletePost', currentId).then((rawData: ResponseType<postProps>) => {
        createMessage('删除成功，2秒后跳转到专栏首页', 'success', 2000)
        setTimeout(()=>{
          router.push({name: 'columnDetail', params: {id: rawData.data.column}})
        }, 2000)
      })
    }
    return {
      currentPost,
      currentHtml,
      currentImageUrl,
      showEditArea,
      modalIsVisible,
      hideAndDelete
    }
  }
})
</script>

<style>

</style>