<template>
  <div>didididi</div>
</template>

<script lang="ts">
export default {
  
}
</script>

<style>

</style>