import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import ColumnDetail from './views/ColumnDetail.vue'
import CreatePost from './views/CreatePost.vue'
import store from './store'
import Signup from './views/Signup.vue'
import axios from 'axios'
import PostDetall from './views/PostDetall.vue'
const routerHistory = createWebHistory()
export const router = createRouter({
   history: routerHistory,
   routes: [
    // 首页
    {
      path: '/',
      name: 'home',
      component: Home
    },
    // 登录
    {
      path: '/Login',
      name: 'login',
      component: Login,
      meta: { redirectAlreadyLogin: true }
    },
    // 专栏详情
    {
      path: '/columnDetail/:id',
      name: 'columnDetail',
      component: ColumnDetail,
    },
    // 新建文章
    {
      path: '/create',
      name: 'createPost',
      component: CreatePost,
      meta: {requireredLogin: true}
    },
    // 注册用户
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
    },
    // 文章详情页
    {
      path: '/posts/:id',
      name: 'posts',
      component: PostDetall,
    },
   ]
})
// 设置路由前置守卫，路由权限管理
router.beforeEach((to, form, next) => {
  const {user, token} = store.state
  const {requireredLogin, redirectAlreadyLogin} = to. meta
  if (!user.isLogin){
    if(token){
      axios.defaults.headers.common.Authorization = `Bearer ${token}`
      store.dispatch('fetchCurrentUser').then(()=>{
        if(redirectAlreadyLogin){
          next('/')
        }else{
          next()
        }
      }).catch((e)=>{
        // console.log(e)
        store.commit('')
        next('/login')
      })
    }else{
      if(requireredLogin){
        next('/login')
      }else{
        next()
      }
    }
  }else{
    if(redirectAlreadyLogin){
      next('/')
    }else{
      next()
    }
  }
})
