<template>
  <div class="file-upload">
    <div class="file-upload-container" @click.prevent="triggerUpload" v-bind="$attrs">
      <slot name="loading" v-if="fileStatus =='loading'">
        <button class="btn btn-primary" disabled>正在上传...</button>
      </slot>
      <slot v-else-if="fileStatus =='success' && uploadedData " name="uploaded" :uploadedData="uploadedData" >
        <button class="btn btn-primary">上传成功</button>
      </slot>
      <slot v-else name="default">
        <button class="btn btn-primary">点击上传</button>
      </slot>
    </div>
    <input
     type="file"
     class="file-input d-none"
     ref="fileInput"
     @change="handleFileChange"
    >
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, PropType, watch} from 'vue'
import axios from 'axios'
// 创建一个联合类型uploadStatus
type uploadStatus = 'read' | 'loading' | 'success' | 'error'
// 创建一个验证函数
type CheckFunction = (file: File) => boolean
export default defineComponent({
  props: {
    // 必须传入请求传入接口
    action: {
      type: String,
      required: true
    },
    //上传前检查
    beforeUpload: {
      type: Function as PropType<CheckFunction>
    },
    UpLOadedData:{
      type: Object
    }
  },
  inheritAttrs: false,
  emits:['file-uploaded', 'file-uploaded-error'],
  setup(props, context){
    // 新建一个传与父组件的对象uploadedData
    const uploadedData = ref(props.UpLOadedData as object)
    // 创建一个响应式的fileStatus(上传对象)对象
    const fileStatus = ref<uploadStatus>(props.UpLOadedData ? 'success' : 'read')
    // 创建fileInput节点
    const fileInput = ref<null | HTMLInputElement>(null)
    watch(() => props.UpLOadedData, (newvalue) => {
      if(newvalue){
        fileStatus.value = 'success'
        uploadedData.value = newvalue
      }
    })
    // 创建triggerUpload点击事件函数，通过点击按钮触发上传文件Input（file ）
    const triggerUpload = () => {
      if (fileInput){
        fileInput.value?.click()
      }
    }
    // 处理文件改变
    const handleFileChange = (e: Event) => {
      const currentTarget = e.target as HTMLInputElement
      if(currentTarget.files){
        // 将files转换成array类型
        const files = Array.from(currentTarget.files)
        if(props.beforeUpload){
          const result = props.beforeUpload(files[0])
          // console.log(result)
          if(!result){
            return  
          }
        }
        fileStatus.value = 'loading'
        const fromData = new FormData()
        fromData.append('file', files[0])
        axios.post(props.action, fromData, {
          headers:{
            'Content-Type' : 'multipart/form-data'
          }
        }).then(resp=>{
          // console.log(resp)
          fileStatus.value = 'success'
          context.emit('file-uploaded', resp.data)
          uploadedData.value = resp.data
        }).catch((error)=>{
          fileStatus.value = 'error'
          context.emit('file-uploaded-error', {error})
        }).finally(()=>{
          if(fileInput.value){
            fileInput.value.value = ''
          }
        })
      }
    }
    return{
      fileInput,
      triggerUpload,
      fileStatus,
      uploadedData,
      handleFileChange
    }
  }
})
</script>

<style scoped lang="less">

</style>