<template>
  <div class="signup-page mx-auto p-3 box">
    <h1>登录DB知乎</h1>
    <Validate-form @form-submit="onFormSubmit" class="from">
      <div class="mb-3">
        <label class="form-label">邮箱地址</label>
        <validate-input
          type="email"
          placeholder="请输入邮箱地址"
          :rules="emailRules"
          v-model="emailVal"
          ref="inputRef"
        ></validate-input>
      </div>
      <div class="mb-3">
        <label class="form-label">用户密码</label>
        <validate-input
          type="password"
          placeholder="请输入用户密码"
          :rules="passwordRules"
          v-model="passwordVal"
        ></validate-input>
      </div>
      <router-link to="/Signup" class="sigup">还没有账户？去注册一个新的吧！</router-link>
      <template v-slot:submit>
        <span class="btn btn-primary">登录</span>
      </template>
    </Validate-form>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref} from 'vue'
  import { useStore } from 'vuex'
  import {useRouter} from 'vue-router'
  import createMessage from '@/components/createMessage'
  import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
  import ValidateForm from '../components/ValidateForm.vue'
  
  export default defineComponent({
    name: 'App',
    components:{
      ValidateInput,
      ValidateForm
    },
    setup(){
      const store = useStore()
      const router = useRouter()
      const inputRef = ref<any>()
      const emailVal = ref('111@test.com')
      const passwordVal = ref('111111')
      const emailRules: RulesProp = [
        {type: 'required', message: '电子邮箱地址不能为空'},
        {type: 'email', message: '请输入正确的电子邮箱格式'}
      ]
      const passwordRules: RulesProp = [
        {type: 'required', message: '密码不能为空'},
        // {type: 'password', message: '请输入正确的密码格式'}
      ]
      const onFormSubmit = (result: boolean) => {
        if(result){
          const payLoad = {
            email: emailVal.value,
            password: passwordVal.value
          }
          store.dispatch('loginAndFetch', payLoad).then(data => {
            createMessage('登录成功 2秒后跳转首页', 'success')
            setTimeout(()=>{
              router.push('/')
            }, 2000)
          }).catch((e)=>console.log(e))
        }
      }
      return{
        emailRules,
        passwordRules,
        emailVal,
        passwordVal,
        onFormSubmit,
        inputRef
      }
    }
  })
</script>
<style scoped lang="less">
  .box {
    h1{
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      margin: 40px 0;
    };
    .from{
      width: 360px;
      margin: 0 auto;
      
      .sigup{
        display: block;
        color: 0d6efd;
        margin: -20px 0 40px 0;
        text-decoration: none;
      }
    }
    .btn{
      margin-bottom: 40px;
    }
  }
</style>