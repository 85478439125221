<template>
  <div class="PersonalColumn" v-if="column">
    <div class="information" >
      <!-- <img src="column.avatar" :alt="column.title"> -->
      <img v-if="column && column.avatar" :src="column.avatar && column.avatar.fitUrl" :alt="column.title">
      <div class="copywriting">
        <p class="name" >{{column.title}}</p>
        <p class="introduce">{{column.description}}</p>
      </div>
    </div>
    <post-list  :list="posts"></post-list>
    <div class="d-grid gap-2 col-4 mx-auto">
      <button
        class="btn btn-outline-primary mt-2 mb-5"
        @click="loadMorePage" v-if="!isLastPage"
      >
        加载更多
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ColumnProps, GlobalDataProps } from '../store'
import { addColumnAvatar } from '../helper'
import useLoadMore from '../hooks/useLoadMore'
import PostList from '../components/PostList.vue'

export default defineComponent({
  components: { PostList },
 
  setup(){
    const store = useStore<GlobalDataProps>()
    const route = useRoute() // 获取路由信息
    // 当前路由传递过来的专栏id,为了让 computed 响应对应的变化，添加响应式对象
    const currentId = ref(route.params.id)
    // 组件挂载时异步获取当前专栏的信息以及专栏下的文章信息
    onMounted(() => {
      store.dispatch('fethColumn', currentId.value)
      store.dispatch('fethPosts', { cid: currentId.value })
    })
    // 检测变化
    // watch(() => route.params, (toParams) => {
    //   // 确保要变化的路径是进入到用户的专栏
    //   if ((toParams && toParams.id) === store.state.user.column) {
    //     // 重新发送请求，在 store 中有对应的缓存设置
    //     store.dispatch('fetchColumn', toParams.id)
    //     store.dispatch('fetchColumnsPosts', { cid: toParams.id })
    //     // 重新赋值，这样 computed 会变化
    //     currentId.value = toParams.id
    //   }
    // })
    // 获取路径传递的currentId查询专栏信息
    const column = computed(() => {
      const selectColumn = store.getters.getColumnById(currentId.value) as ColumnProps | undefined
      if (selectColumn) {
        addColumnAvatar(selectColumn, 100, 100)
      }
      return selectColumn
    }) // 专栏信息
    const posts = computed(() => store.getters.getPostsByCid(currentId.value))
    const count = computed(() => store.getters.getPostsCountByCid(currentId.value))
    const currentPage = computed(() => store.getters.getPostsCurrentPageByCid(currentId.value))
    const { loadMorePage, isLastPage } = useLoadMore('fethPosts', count, { pageSize: 3, currentPage: currentPage.value ? currentPage.value+1 : 2 }, currentId.value)
    return {
      column,
      posts,
      loadMorePage,
      isLastPage,
      currentPage,
    }
  }
})
</script>

<style scoped lang="less">
.PersonalColumn{
  margin: auto;
  padding: 20px;
  width:720px;

  .information{
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;

    img{
      margin-right: 20px;
      width: 155px;
      height: 155px;
      border-radius: 78px;
    }
    .copywriting{
      margin-top: 30px;
      p{
        padding: 0;
        margin: 0;
      }
      .name{
        font-size: 24px;
        font-weight: 500;
      }
      .introduce{
        font-size: 16px;
        color: #888;
      }
    }
  }
 
}

</style>