<template>
  <div class="signup-page mx-auto p-3 box">
    <h1>注册DB知乎账户</h1>
    <Validate-form @form-submit="onFormSubmit" class="from">
      <div class="mb-3">
        <label class="form-label">邮箱地址</label>
        <validate-input
          type="email"
          placeholder="请输入邮箱地址"
          :rules="emailRules"
          v-model="formData.email"
          ref="inputRef"
        ></validate-input>
      </div>
      <div class="mb-3">
        <label class="form-label">昵称</label>
        <validate-input
          type="required"
          :rules="nameRules"
          placeholder="请输入昵称"
          v-model="formData.nickName"
        ></validate-input>
      </div>
      <div class="mb-3">
        <label class="form-label">密码</label>
        <validate-input
          type="password"
          placeholder="请输入密码"
          :rules="passwordRules"
          v-model="formData.password"
        ></validate-input>
      </div>
      <div class="mb-3">
        <label class="form-label">重复密码</label>
        <validate-input
          type="password"
          placeholder="请再次输入密码"
          :rules="repeatPasswordRules"
          v-model="formData.repeatPassword"
        ></validate-input>
      </div>
      <router-link to="/Login" class="login">还没有账户？去注册一个新的吧！</router-link>
      <template v-slot:submit>
        <span class="btn btn-primary">注册新用户</span>
      </template>
    </Validate-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive} from 'vue'
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'
import createMessage from '@/components/createMessage'
import ValidateInput, { RulesProp } from '../components/ValidateInput.vue'
import ValidateForm from '../components/ValidateForm.vue'
import axios from 'axios'

export default defineComponent({
    name: 'Signup',
    components:{
      ValidateInput,
      ValidateForm
    },
    setup(){
      const formData = reactive({
        email: '',
        nickName: '',
        password: '',
        repeatPassword:''
      })
      const router = useRouter()
      const emailRules: RulesProp = [
        {type: 'required', message: '电子邮箱地址不能为空'},
        {type: 'email', message: '请输入正确的电子邮箱格式'}
      ]
      const nameRules: RulesProp = [
        {type: 'required', message: '昵称不能为空'},
      ]
      const passwordRules: RulesProp = [
        {type: 'required', message: '密码不能为空'},
      ]
      const repeatPasswordRules: RulesProp = [
        {type: 'required', message: '重复密码不能为空'},
        {type:'custom',
          validator: () => {
            return formData.password === formData.repeatPassword
          },
          message:'密码不相同'
        }
      ]
      const onFormSubmit = async(result: boolean) => {
        if(result){
          const payLoad = {
            email: formData.email,
            password: formData.password,
            nickName: formData.nickName,
          }
          // axios.post('/users', payLoad)
          axios.post('/users', payLoad).then(data => {
            console.log(data)
            if(data.data.code === '400'){
              createMessage(data.data.error, 'error')
            }else if(data.data.code === '200'){
              createMessage('注册成功 2秒后跳转登录页', 'success')
              setTimeout(()=>{
                router.push('/login')
              }, 2000)
            }
          })
          .catch((e)=>{
            console.log(e)
          })

        }
      }
      return{
        formData,
        emailRules,
        nameRules,
        passwordRules,
        repeatPasswordRules,
        onFormSubmit
      }
    }
  })
</script>

<style scoped lang="less">
.box{
  h1{
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 40px 0;
  }
  .from{
      width: 360px;
      margin: 0 auto;
      
      .login{
        display: block;
        color: 0d6efd;
        margin: -20px 0 40px 0;
        text-decoration: none;
      }
      .btn{
        margin-bottom: 40px;
      }
    }

}
</style>