<template>
  <ul class="row">
    <li class="col-4 mb-4" v-for="column in columnList" :key="column._id">
      <div class="card h-100 shadow-sm">
        <div class="card-body text-center" >
          <img class="rounded-circle border border-light my-3 border border-secondary-subtle" :src="column.avatar && column.avatar.url" :alt="column.title">
          <h5 class="card-title">{{ column.title }}</h5>
          <p class="card-text text-start">{{ column.description }}</p>
          <!-- 路由也可写成:to="{name:'column', params:{id: column.id}}" -->
          <!-- <router-link class="btn btn-outline-primary my-3" :to="{name:'column', params:{id: column.id}}">进入专栏</router-link> -->
          <router-link class="btn btn-outline-primary" :to="`/columnDetail/${column._id}`">进入专栏</router-link>
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent, PropType, computed} from 'vue'
import { getAssetsFile } from '@/utils/common';
import { ColumnProps } from '../store'
// import { addColumnAvatar} from '@/helper'

export default defineComponent({
  name: 'columnList',
  props: {
    list: {
      type: Array as PropType<ColumnProps[]>,
      required: true //必须具备或必须满足上面属性要求
    }
  },
  setup(props){
    const columnList = computed(()=>{
      return props.list.map(column => {
        // console.log(column)
        if(!column.avatar){
          column.avatar = {
            url : 'column.png'
          }
        }
        // addColumnAvatar(column, 50, 50)
        return column
      })
    })

    return{ columnList, getAssetsFile }
  }
})
</script>

<style scoped lang="less">
ul{
  padding: 0;
  li{
    list-style-type: none; 
    .card-body{
      img{
        width: 70px;
        height: 70px;
      };
      p{
        color: #666;
        height: 60px;
        line-height: 20px;
        overflow: hidden; /* 隐藏溢出部分 */  
        display: -webkit-box; /* 使用WebKit特定的属性 */  
        -webkit-line-clamp: 3; /* 设置显示的行数 */  
        -webkit-box-orient: vertical; /* 容器方向设置为垂直 */  
        text-overflow: ellipsis; /* 显示省略号 */  
      };
    }
  }
}
</style> 