// 当用户点击在指定的元素外部时，返回一个值为 true 的响应式引用；
import {ref, onMounted, onUnmounted, Ref} from 'vue'

const useClickOutside = (elementRef: Ref<null | HTMLElement>) => {
  const isClickOutside = ref(false)
  const handler = (e:MouseEvent) => {
    if(elementRef.value){
      if (elementRef.value.contains(e.target as HTMLElement)) {
        isClickOutside.value = false
      }else{
        isClickOutside.value = true
      }
    }
  }
  onMounted(() => {
    document.addEventListener('click', handler)
  })
  onUnmounted(() => {
    document.removeEventListener('click', handler)
  })
  return isClickOutside
}

export default useClickOutside
