import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9e83d1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "#message" }, [
    (_ctx.isVisible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.classObject, "alert message-info fixed-top w-50 mx-auto d-flex justify-content-between mp-2"]),
          role: "alert"
        }, [
          _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.message), 1),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "aria-label": "Close",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)), ["prevent"]))
          })
        ], 2))
      : _createCommentVNode("", true)
  ]))
}