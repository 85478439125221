import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24f4a166"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "columnList" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "img"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (post) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "article",
        key: post._id
      }, [
        _createVNode(_component_router_link, {
          to: `/posts/${post._id}`,
          class: "columnName"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(post.title), 1)
          ]),
          _: 2
        }, 1032, ["to"]),
        _createElementVNode("div", _hoisted_2, [
          (post.image)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ((typeof post.image != 'string'))
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: (post.image.url),
                      alt: ""
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(post.excerpt), 1)
        ]),
        _createElementVNode("p", null, _toDisplayString(post.createdAt), 1)
      ]))
    }), 128))
  ]))
}