import { ComputedRef, ref, computed } from "vue";
import { useStore } from 'vuex'
// 定义加载参数的类型
interface LoadParams {
  currentPage: number;
  pageSize: number;
}
// 定义一个加载更多的函数useLoadMore需要；传入三个参数（actionName--获取参数时传入action名称，total--总数，params--调用接口时需要的参数）
const useLoadMore = (actionName: string, total: ComputedRef<number>, 
  params: LoadParams = { currentPage: 2, pageSize: 6 },cid?: string | string[]) => {
  const store = useStore()
  // 定义一个默认页码
  const currentPage = ref(params.currentPage)
  // 定义获取接口数据函数所需参数对象
  const requestParams = computed(() => ({
    currentPage: currentPage.value,
    pageSize: params.pageSize
  }))
  // / 定义一个加载更多的函数`
  const loadMorePage = () => {

    if(cid){
      store.dispatch(actionName, {cid, params:requestParams.value}).then(()=>{
        currentPage.value++
      })
    }else{
      store.dispatch(actionName, requestParams.value).then(()=>{
        currentPage.value++
      })
    }
  }
  // 定义一个最后一个页面
  const isLastPage = computed(() => {
    // Math.ceil是返回大于参数x的最大整数，即对浮点数向上取整
    console.log(total.value, params, currentPage.value)
    return Math.ceil(total.value/params.pageSize) < currentPage.value
  })
  return {
    loadMorePage,
    isLastPage,
    currentPage
  }
}

export default useLoadMore